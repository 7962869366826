.navbar {
  background-color: white;
}

.navbar-nav .nav-link.active {
  color: #667AB3;
}

.main-hero {
  background-color: #4D485B;
  color: whitesmoke;
}

.values {
  background-color: white;
}

.gallery-card {
  background-color: #4D485B;
  color: whitesmoke;
}

.gallery-card-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery-card-description {
  opacity: 0;
}

.gallery-card:hover .gallery-card-description {
  opacity: 1;
  transition: 1s;
}

.gallery-card:hover .gallery-card-title {
  opacity: 0;
  transition: 300ms;
}

.cards-wrapper {
  display: flex;
  justify-content: center;
}
.card img {
  max-width: 100%;
  max-height: 100%;
}
.card {
  margin: 0 0.5em;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  border: none;
  border-radius: 0;
}
.carousel-inner {
  padding: 1em;
}
.carousel-control-prev,
.carousel-control-next {
  background-color: #e1e1e1;
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.blog-carousel {
  background-color: #e8e8e8;
}

.blog-card-image {
  width: 75%;
  height: 75%;
}

.react-multiple-carousel__arrow {
  bottom: 0;
}

.contact {
  background-color: #4D485B;
  color: white;
}

.loading {
  background-color: #4D485B; 
  height: 100vh;
  color: whitesmoke;
}

.product {
  background-color: #E0E7F4;
}

.product-description {
  background-color: white;
}

.contact-page {
  background-color: #4D485B;
  color: whitesmoke;
}

.contact-side {
  background-color: white;
  color: black;
}

.banner {
  background-color: #4D485B;
  color: whitesmoke;
}
